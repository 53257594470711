import { loginRefresh } from './api'
import { LoginResponseData, LoginResponse, User, License } from './api'

const isBrowser = typeof window !== `undefined`
const LS_NAMESPACE = 'Auth.data'

export const DEFAULT_LOGIN_PATH = '/'
export const DEFAULT_DASH_PATH = '/dashboard/'

/**
 * Gets Data stored in local Storage
 * @returns LoginResponseData | {}
 */
export type AuthData = LoginResponseData

export const stub: AuthData = {
  access_token: null,
  refresh_token: null,
  access_token_data: null,
  refresh_token_data: null,
  user: {
    username: '',
    created_date: null,
    email: '',
    full_name: '',
    last_login_date: null,
    last_login_ip: null,
    logins: [],
    logins_failed: [],
    logins_failed_count: 0,
    password: '',
    preferences: '',
    roles: []
  }
}

/**
 * Checks user is logged in using local storage and checking expiry
 * @returns [Boolean]
 */
export const isLoggedIn = (): boolean => {
  if (!isBrowser) return false

  const authData = getAuthData()

  if (!authData?.access_token_data) return false

  if (!hasExpired(authData.access_token_data.exp)) return true

  return !hasExpired(authData.access_token_data.exp)
}

export const getAuthData = (): AuthData =>
  isBrowser && window.localStorage[LS_NAMESPACE]
    ? JSON.parse(window.localStorage[LS_NAMESPACE])
    : stub

/**
 * Extracts access token from login API data
 * @returns [String] Token
 */
export const getToken = (): string => {
  const { access_token } = getAuthData()
  return access_token || ''
}

/**
 * Extracts refresh access token from login API data
 * @returns [String] Token
 */
export const getRefreshToken = (): string => {
  const { refresh_token } = getAuthData()
  return refresh_token || ''
}

/**
 * Extracts user data from login API data
 * @returns [Object] User data
 */
export const getUserData = (): User => {
  const authData = getAuthData()
  return authData?.user?.full_name ? authData.user : stub.user
}

/**
 * Extracts license data from login API data
 * @returns [Object] license data
 */
export const getLicenseData = (): License | false => {
  const authData = getAuthData()
  return authData?.license?.customer.name ? authData.license : false
}

/**
 * Checks if NumericDate has expired
 * @param time NumericDate
 * @returns [Object] user data
 */
export const hasExpired = (time: number) => {
  const now = Math.floor(Date.now() / 1000)
  return time && time - now < 0
}

/**
 * Checks if token can and should be refresh
 * @returns [Boolean]
 */
export const shouldRefresh = () => {
  const { access_token_data, refresh_token_data } = getAuthData()

  if (!refresh_token_data) return false

  if (!access_token_data && !hasExpired(refresh_token_data.exp)) return true

  return (
    access_token_data &&
    hasExpired(access_token_data.exp) &&
    !hasExpired(refresh_token_data.exp)
  )
}

/**
 * Sets API data to local storage
 * @param data
 * @returns
 */
export const setAuthLSData = (data: {} | LoginResponseData) =>
  isBrowser && window.localStorage.setItem(LS_NAMESPACE, JSON.stringify(data))

export const getAuthLSData = (): AuthData => {
  try {
    if (isBrowser) {
      const str = window.localStorage.getItem(LS_NAMESPACE)
      if (str) {
        return JSON.parse(str) as AuthData
      }
    }
  } catch (error) {
    console.log('Could not retrieve auth data locally')
  }
  return stub
}

/**
 * Sets API data to local storage
 */
export const deleteAuthLSData = () =>
  isBrowser && window.localStorage.removeItem(LS_NAMESPACE)

/**
 * Check if refresh Token has expired
 * @returns [boolean]
 */
export const canRefresh = (): boolean => {
  const { refresh_token_data } = getAuthData()

  if (!refresh_token_data) return false

  return !hasExpired(refresh_token_data.exp)
}

/**
 * Refresh access token and user data
 * @param refreshToken
 * @returns [Promise] API response
 */
export const refreshData = async (): Promise<
  false | void | LoginResponse | undefined
> => {
  if (!isBrowser) return false

  const refreshResponse = await loginRefresh()
    .then((response) => {
      if (response.status === 200 && response.data) {
        return response
      }

      if (typeof response !== 'undefined' && response.status === 401) {
        logout(() => {
          if (isBrowser && window.location.pathname !== '/') {
            window.location.href = '/'
          }
        })
      }
    })
    .catch((error) => {
      console.log('error', error)
      logout(() => {
        if (isBrowser && window.location.pathname !== '/') {
          window.location.href = '/'
        }
      })
    })

  return refreshResponse
}

export const getLoginStatus = (authData: AuthData | LoginResponseData) => {
  if (!authData?.access_token_data) return false

  if (!hasExpired(authData.access_token_data.exp)) return true

  return !hasExpired(authData.access_token_data.exp)
}

/**
 * Safely extracts user data from local storage
 * @returns [false | {} | User]  User data
 */
export const getCurrentUser = (): false | {} | User =>
  isBrowser && getUserData()

/**
 * Logs user out locally
 * @param callback
 */
export const logout = (callback?: () => void) => {
  if (!isBrowser) return
  deleteAuthLSData()
  callback && callback()
}
