import React from 'react'

export type ColorModeState = {
  colorMode: 'light' | 'dark'
  toggleColorMode: () => void
}

const defaultState: ColorModeState = {
  colorMode: 'dark',
  toggleColorMode: () => {}
}

const ColorModeContext = React.createContext(defaultState)

export default ColorModeContext
