import http from '../http'
import { ApiResponse, ScrollingApiResponse } from './types'
import { TCreateCredentialProps } from './credentials'
import { JumpHost } from './jumphosts'
import { removeEmpty } from '../misc'

// TODO type entire object
export type Host = {
  id: string
  active: boolean
  authentication_status: string
  credentials_id: string
  data: object
  date_first_seen: string
  date_last_seen: string
  first_seen_ip_addr: string
  last_seen_ip_addr: string
  host_alias: string | null
  host_id: string
  hostname: string
  jump_hosts: JumpHost['name'][]
  os: {
    info: {
      platform: string
      node: string
      os_release: {
        pretty_name: string
        [key: string]: any
      }
      [key: string]: any
    }
    [key: string]: any
  }
  queue_name: string
  run_id: string
  sandfly_whitelist: string[]
  ssh_key_fingerprint: string
  ssh_port: number
  tags: string[]
  results: {
    total: number
    alert: number
    error: number
    pass: number
  }
}

export type HostSummary = Host & {
  os_hardware_cpu_bugs: string[]
  os_hardware_cpu_cores: number
  os_hardware_cpu_mhz: number
  os_hardware_cpu_model_name: string
  os_hardware_dmi_bios_vendor: string
  os_hardware_dmi_chassis_vendor: string
  os_hardware_dmi_product_family: string
  os_hardware_dmi_product_name: string
  os_hardware_dmi_sys_vendor: string
  os_info_arch: string
  os_info_machine: string
  os_info_node: string
  os_info_os_release_name: string
  os_info_os_release_pretty_name: string
  os_info_os_release_version: string
  os_info_os_release_version_codename: string
  os_info_release: string
  os_info_uptime_date: string
  os_info_uptime_days: number
  os_performance_cpu_fifteen_min: number
  os_performance_cpu_fifteen_min_core_adjusted: number
  os_performance_cpu_five_min: number
  os_performance_cpu_five_min_core_adjusted: number
  os_performance_cpu_one_min: number
  os_performance_cpu_one_min_core_adjusted: number
  results_total: number
  results_alert: number
  results_error: number
  results_pass: number
}

export type Hosts = HostSummary[]
export type HostsResponse = ScrollingApiResponse<Hosts>
export type HostResponse = ApiResponse<Host>
export interface ICreateHosts extends TCreateCredentialProps {
  ip_list: string[]
  ip_range: string[]
  ssh_port: number
  tags: string[]
  jump_hosts: JumpHost['name'][]
  credentials_mode: 'new' | 'existing'
  credentials_id: string
  queue_name: string
  [key: string]: any
}

export interface IUpdateHost {
  host_id: string
  tags?: string[]
  credentials_id?: string
  jump_hosts?: JumpHost['name'][]
  queue_name?: string
  [key: string]: any
}

export const getHosts = async (): Promise<HostsResponse> =>
  await http.get(`/hosts?summary=true`)

export const getHost = async (id: string): Promise<HostResponse> =>
  await http.get(`/hosts/${id}`)

export const deleteHost = async (id: string): Promise<ApiResponse<string>> =>
  await http.delete(`/hosts/${id}`)

export const deleteHosts = async (
  ids: string[]
): Promise<ApiResponse<string>> =>
  await http.delete(`/hosts`, { data: { host_ids: ids } })

export const createHosts = async (
  data: ICreateHosts
): Promise<HostResponse> => {
  const isBrowser = window !== undefined
  const { ssh_key_b64 = '', ssh_key_certificate_b64 = '', ip_list = [] } = data

  const cleanData = removeEmpty({
    ...data,
    ip_list: ip_list.filter((ip) => ip !== ''),
    ssh_key_b64: isBrowser && btoa(ssh_key_b64),
    ssh_key_certificate_b64: isBrowser && btoa(ssh_key_certificate_b64)
  })

  return await http.post(`/hosts`, cleanData)
}

export const updateHost = async (
  data: IUpdateHost
): Promise<ApiResponse<string>> => {
  const { host_id, ...rest } = data

  const cleanData = removeEmpty(rest)

  return await http.put(`/hosts/${host_id}`, cleanData)
}
