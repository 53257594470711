import { GridFilterModel, GridSortItem } from '@mui/x-data-grid-pro'
import http from '../http'
import { SandflyEngine, SandflyType } from './sandflies'
import { ApiResponse } from './types'

const SEARCH_LIMIT = 10000

export type TResultSummary = {
  'data.end_time': string
  'data.euid': number
  'data.euid_username': string
  'data.exec_seconds': number
  'data.masked': boolean
  'data.name': string
  'data.output_format': string
  'data.pid': number
  'data.severity': number
  'data.start_time': string
  'data.status': TResultStatus
  'data.status_msg': string
  'data.tags': string[]
  'data.uid': number
  'data.uid_username': string
  'header.credentials_id': string
  'header.end_time': string
  'header.host_id': string
  'header.hostname': string
  'header.ip_addr': string
  'header.manual_scan': false
  'header.queue_name': string
  'header.run_id': string
  'header.ssh_key_fingerprint': string
  'header.ssh_port': number
  'header.start_time': string
  'header.status': string
  'header.status_msg': string
  'header.tags': string[]
  id: string
  ops_notes: string | null
  ops_originator: string | null
  ops_owner: string | null
}

type ResultData = {
  [key in TResultDataKey]?: {
    [key: string]: any
  }
}

export type TResultDataKey =
  | 'process'
  | 'file'
  | 'directory'
  | 'user'
  | 'log'
  | 'atjob'
  | 'cron'
  | 'os'
  | 'error'
  | 'systemd'
  | 'systemd_user'
  | 'kernel_module'

export type TResultStatus = 'pass' | 'error' | 'alert'

export type TResultDetail = {
  id: string
  data: {
    end_time: string
    euid: number
    euid_username: string
    engine: `sandfly_engine_${SandflyEngine}`
    exec_seconds: number
    name: string
    output_format: string
    pid: number
    results: ResultData & {
      explanation: string
      response: {
        directory: {
          error: boolean
        }
        file: {
          error: boolean
        }
        log: {
          error: boolean
        }
        process: {
          error: boolean
          killed: boolean
          suspended: boolean
        }
        user: {
          error: boolean
        }
      }
    }
    severity: number
    start_time: string
    status: TResultStatus
    status_msg: string
    tags: string[]
    type: SandflyType
    uid: number
    uid_username: string
  }
  header: {
    credentials_id: string
    end_time: string
    host_id: string
    hostname: string
    ip_addr: string
    manual_scan: boolean
    queue_name: string
    run_id: string
    ssh_key_fingerprint: string
    ssh_port: 22
    start_time: string
    status: TResultStatus
    status_msg: string
    tags: []
  }
  ops: {
    notes: string | null
    originator: string | null
    owner: string | null
    state: string
  }
}

export type TResultsSummaryResponse = ApiResponse<{
  data: TResultSummary[]
  total: number
  scroll_id: string
}>

export type TResultsResponse = ApiResponse<{
  data: TResultDetail[]
  total: number
  scroll_id: string
}>

export type TResultResponse = ApiResponse<TResultDetail>

export type TGetResultsFilterProps = { [key: string]: any } & {
  time_since?: string
  status?: 'alert' | 'pass' | 'error'
  size?: number
  flatten?: boolean
  summary?: boolean
  filter?: GridFilterModel
  sort?: GridSortItem[]
}

export const getSummaryResults = async (
  filters: TGetResultsFilterProps = {}
): Promise<TResultsSummaryResponse> =>
  await http.post(`/results`, {
    size: SEARCH_LIMIT,
    flatten: true,
    summary: true,
    ...filters
  })

export const getFullResults = async (
  filters: TGetResultsFilterProps = {}
): Promise<TResultsResponse> =>
  await http.post(`/results`, {
    size: SEARCH_LIMIT,
    flatten: false,
    summary: false,
    ...filters
  })

export const getResult = async (id: string): Promise<TResultResponse> =>
  await http.get(`/results/${id}`)

export const deleteResult = async (id: string): Promise<string> =>
  await http.delete(`/results`, {
    data: {
      filter: {
        items: [
          {
            columnField: 'id',
            operatorValue: 'equals',
            value: `${id}`
          }
        ]
      }
    }
  })

export const deleteResultsById = async (ids: string[]): Promise<string> =>
  await http.delete(`/results`, {
    data: {
      result_ids: ids
    }
  })

export const deleteResults = async (filter: GridFilterModel): Promise<string> =>
  await http.delete(`/results`, {
    data: {
      filter: filter
    }
  })

export const deleteAllResults = async (): Promise<string> =>
  await http.delete(`/results/all`)
