import React from 'react'

type State = {
  isLoading: boolean
  loading: boolean[]
  setLoading: (open?: boolean) => void
}

const defaultState: State = {
  isLoading: true,
  loading: [],
  setLoading: () => {}
}

const LoadingContext = React.createContext(defaultState)

export default LoadingContext
