import http from '../http'
import { ApiResponse } from './types'
import { AxiosResponse } from 'axios'

export type Sandfly = {
  active: boolean
  custom?: boolean
  date_added: string
  description: string
  format: string
  id: string
  max_cpu_load: number
  max_disk_load: number
  max_timeout: number
  name: string
  options: {
    engines: SandflyEngineName
    explanation: string
    process: {
      command: string[]
      name: string[]
      name_ignore: string[]
      uid_name: string[]
    }
    response: {
      process: {
        kill: boolean
        suspend: boolean
      }
    }
  }
  os_compat: {
    linux: string[]
  }
  severity: number
  tags: string[]
  type: SandflyType
  version: string
}

export type Sandflies = {
  data: Sandfly[]
}

export type UpdateSandflyProps = {
  name: Sandfly['name']
  response: Sandfly['options']['response']
}

export type SandflyType =
  | 'user'
  | 'incident'
  | 'process'
  | 'template'
  | 'policy'
  | 'file'
  | 'directory'
  | 'log'
  | 'recon'
  | 'error'

export type SandflyEngine =
  | 'dir'
  | 'file'
  | 'process'
  | 'user'
  | 'utmp'
  | 'wtmp'
  | 'btmp'
  | 'lastlog'
  | 'cron'
  | 'at_jobs'
  | 'log_tampering_lastlog_wtmp_missing_record'
  | 'log_tampering_lastlog_history_missing_record'
  | 'os_identify'
  | 'process_masquerade_binary_renamed'
  | 'process_masquerade_binary_mismatched'
  | 'process_masquerade_mixed_case'
  | 'process_binary_time_identical'
  | 'user_password_hash_duplicates'
  | 'error'
  | 'systemd'
  | 'systemd_session'
  | 'kmodules'

export type SandflyEngineName = `sandfly_engine_${SandflyEngine}`

export const SANDFLY_TYPES: SandflyType[] = [
  'user',
  'incident',
  'process',
  'template',
  'policy',
  'file',
  'directory',
  'log',
  'recon',
  'error'
]

export type SandflyResponse = ApiResponse<Sandfly>
export type SandfliesResponse = ApiResponse<Sandflies>

export const getSandflies = async (): Promise<SandfliesResponse> =>
  await http.get(`/sandflies`)

export const getSandfly = async (id: string): Promise<SandflyResponse> =>
  await http.get(`/sandflies/name/${id}`)

export const deleteSandfly = async (id: string): Promise<SandflyResponse> =>
  await http.delete(`/sandflies/name/${id}`)

export const deactivateSandfly = async (
  id: string
): Promise<ApiResponse<string>> =>
  await http.put(`/sandflies/name/${id}/deactivate`)

export const activateSandfly = async (
  id: string
): Promise<ApiResponse<string>> =>
  await http.put(`/sandflies/name/${id}/activate`)

export const createSandfly = (data: Sandfly): Promise<ApiResponse<string>> => {
  return http.post(`/sandflies`, {
    [data.name]: data
  })
}

export const updateSandfly = (data: Sandfly): Promise<ApiResponse<string>> => {
  return http.put(`/sandflies`, {
    [data.name]: data
  })
}

export const updateSandflyResponse = ({
  name,
  response
}: UpdateSandflyProps): Promise<ApiResponse<string>> => {
  return http.put(`/sandflies/response/${name}`, {
    process: response
  })
}

// Todo: Replace with bulk action
export const deactivateSandflies = async (
  ids: string[]
): Promise<AxiosResponse<any>[]> => {
  const promiseArray = ids.map((id) =>
    http.put(`/sandflies/name/${id}/deactivate`)
  )
  const values = await Promise.all(promiseArray)
  return values
}

// Todo: Replace with bulk action
export const activateSandflies = async (ids: string[]) => {
  const promiseArray = ids.map((id) =>
    http.put(`/sandflies/name/${id}/activate`)
  )
  const values = await Promise.all(promiseArray)
  return values
}
