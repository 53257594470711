import { useLocalStorageValue } from '@mantine/hooks'
import {
  addLicense as addLicenseApi,
  ApiResponse,
  deleteLicense as deleteLicenseApi,
  getLicense,
  LicenseResponse,
  updateLicense as updateLicenseApi
} from '@utils/api'
import { isLoggedIn } from '@utils/auth'
import LicenseContext from '@utils/contexts/LicenseContext'
import useApi, { wasSuccess } from '@utils/hooks/useApi'
import { licenseStub, LS_LICENSE_NAMESPACE } from '@utils/license'
import React, { FC, useEffect, useState } from 'react'

const LicenseProvider: FC = ({ children }) => {
  const [license, setLicense] = useLocalStorageValue({
    key: LS_LICENSE_NAMESPACE,
    defaultValue: JSON.stringify(licenseStub)
  })

  const [loading, setLoading] = useState(!!!license)

  const { makeRequest, error } = useApi<LicenseResponse>({
    apiMethod: getLicense,
    requestOnMount: false
  })

  const addApi = useApi<LicenseResponse>({
    apiMethod: addLicenseApi,
    requestOnMount: false
  })

  const updateApi = useApi<LicenseResponse>({
    apiMethod: updateLicenseApi,
    requestOnMount: false
  })

  const deleteApi = useApi<ApiResponse<string>>({
    apiMethod: deleteLicenseApi,
    requestOnMount: false
  })

  const refreshData = () => {
    makeRequest().then((res) => {
      if (res && JSON.stringify(res.data) !== license) {
        setLicense(res && wasSuccess(res) ? JSON.stringify(res.data) : '')
        setLoading(false)
      } else {
        setLoading(false)
      }
    })
  }

  const addLicense = async (data: any): Promise<any> => {
    setLoading(true)

    return addApi.makeRequest(data).then((res: ApiResponse) => {
      if (wasSuccess(res)) {
        refreshData()

        addApi.enqueueSnackbar('License Successfully Added', {
          variant: 'success'
        })
      } else {
        setLoading(false)
      }
    })
  }

  const updateLicense = async (data: any): Promise<any> => {
    setLoading(true)

    return updateApi.makeRequest(data).then((res: ApiResponse) => {
      if (wasSuccess(res)) {
        refreshData()

        addApi.enqueueSnackbar('License Successfully Updated', {
          variant: 'success'
        })
      } else {
        setLoading(false)
      }
    })
  }

  const deleteLicense = async () => {
    setLoading(true)

    return deleteApi.makeRequest().then((res: ApiResponse) => {
      if (wasSuccess(res)) {
        clearLicenseData()
      }
      setLoading(false)
    })
  }

  const clearLicenseData = () => {  
    setLicense(JSON.stringify(licenseStub))
  }

  useEffect(() => {
    const loggedIn = isLoggedIn()
    loggedIn && refreshData()
  }, [license])

  return (
    <LicenseContext.Provider
      value={{
        license: license && JSON.parse(license),
        loading,
        refreshData,
        addLicense,
        updateLicense,
        deleteLicense,
        clearLicenseData,
        error
      }}
    >
      {children}
    </LicenseContext.Provider>
  )
}

export default LicenseProvider
