import http from '../http'
import { SandflyType } from './sandflies'
import { ApiResponse, ScrollingApiResponse } from './types'

export type TSchedule = {
  date_first_run: string
  date_last_run: string
  date_next_run: string
  id: string
  minutes_timer_high: number
  minutes_timer_low: number
  name: string
  number_of_runs: number
  paused: false
  priority: number
  sandfly_selection_percent: number
  sandfly_type: SandflyType[]
  tags: string[]
}

export const getSchedules = async (): Promise<
  ScrollingApiResponse<TSchedule[]>
> => await http.get(`/schedule`)

export type TScheduleConfig = {
  name: string
  host_mode: 'all' | 'tag'
  priority: number
  minutes_timer_low: number
  minutes_timer_high: number
  tags: string[]
  paused: boolean
  sandfly_type: SandflyType[]
  sandfly_selection_percent: number
}

export type TScheduleResponse = ApiResponse<TSchedule>

export const createSchedule = async (
  config: TScheduleConfig
): Promise<ApiResponse> => await http.post(`/schedule`, config)

export const updateSchedule = async (
  config: TScheduleConfig
): Promise<TScheduleResponse> =>
  await http.put(`/schedule/${config.name}`, config)

export const deleteSchedule = async (
  id: string
): Promise<ApiResponse<string>> => await http.delete(`/schedule/${id}`)

export const pauseSchedule = async ({
  id,
  pause
}: {
  id: string
  pause: boolean
}): Promise<ApiResponse<string>> => {
  const endpoint = pause ? `/schedule/pause/${id}` : `/schedule/unpause/${id}`

  return await http.put(endpoint)
}

export const getSchedule = async (id: string): Promise<TScheduleResponse> =>
  await http.get(`/schedule/${id}`)

export const deleteSchedules = async (ids: string[]): Promise<string> =>
  await http.delete(`/schedule`, {
    data: {
      schedule_ids: ids
    }
  })
