import { removeEmpty } from '@utils/misc'
import http from '../http'
import { ApiResponse, ScrollingApiResponse } from './types'

// Alerts Syslog
export type JumpHost = {
  hostname: string
  id: string
  name: string
  ssh_port: number
}
export type JumpHosts = (JumpHost & {
  id: string
})[]
export type JumpHostsResponse = ScrollingApiResponse<JumpHosts>
export type JumpHostResponse = ApiResponse<JumpHost>

export type TCreateJumpHost = {
  name: string
  hostname: string
  ssh_port: number
  credentials_id: string
}

export const createJumpHost = async (
  props: TCreateJumpHost
): Promise<ApiResponse<string>> => {
  const cleanProps = removeEmpty(props)
  return await http.post(`/jumphosts/${props.name}`, cleanProps)
}

export const getJumpHosts = async (): Promise<JumpHostsResponse> =>
  await http.get(`/jumphosts`)

export const getJumpHost = async (id: string): Promise<JumpHostResponse> =>
  await http.get(`/jumphosts/${id}`)

export const deleteJumpHost = async (
  id: string
): Promise<ApiResponse<string>> => await http.delete(`/jumphosts/${id}`)

export const deleteJumpHosts = async (
  ids: string[]
): Promise<ApiResponse<string>> =>
  await http.delete(`/jumphosts`, {
    data: {
      jump_hosts: ids
    }
  })

export const updateJumpHost = async (
  props: TCreateJumpHost
): Promise<ApiResponse<string>> => {
  const cleanProps = removeEmpty(props)
  return await http.put(`/jumphosts/${props.name}`, cleanProps)
}
