import { License } from '@utils/api'
import React from 'react'

export type ContextState = {
  license?: License | null
  loading: boolean
  refreshData: () => void
  addLicense: (data?: { license_key: string }) => void
  updateLicense: (data?: { license_key: string }) => void
  deleteLicense: () => void
  clearLicenseData: () => void
  error: any
}

const defaultState: ContextState = {
  license: null,
  loading: true,
  refreshData: () => {},
  addLicense: () => {},
  updateLicense: () => {},
  deleteLicense: () => {},
  clearLicenseData: () => {},
  error: null
}

const LicenseContext = React.createContext(defaultState)

export default LicenseContext
