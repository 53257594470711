import http from '../http'
import { HostsResponse } from './hosts'
import { Sandfly } from './sandflies'
import { ApiResponse } from './types'

export type TWhitelistSandflyOnHostConfig = {
  host: string
  sandfly: string
}

export type TWhitelist = {
  sandfly_whitelist: Sandfly['name'][]
}

export type TWhitelistResponse = HostsResponse

export const whitelistSandflyOnHost = async ({
  host,
  sandfly
}: TWhitelistSandflyOnHostConfig): Promise<ApiResponse<string>> =>
  await http.put(`/whitelist/hosts/${host}`, { sandfly_whitelist: [sandfly] })

export const getWhitelistForHost = async (
  host: string
): Promise<ApiResponse<string>> => await http.put(`/whitelist/hosts/${host}`)

export const getWhitelist = async (): Promise<TWhitelistResponse> =>
  await http.get(`/whitelist/hosts`)

export const DeleteWhitelistSandflyOnHost = async (
  config: TWhitelistSandflyOnHostConfig
): Promise<TWhitelistResponse> =>
  await http.delete(`/whitelist/hosts/${config.host}`, {
    data: {
      sandfly_whitelist: [config.sandfly]
    }
  })

export const deleteWhitelists = async (ids: string[]) => {
  const promiseArray = ids.map((id) =>
    http.delete(`/whitelist/hosts/${id}`, { data: { sandfly_whitelist: [] } })
  )
  const values = await Promise.all(promiseArray)

  return values
}

export const whitelistSandfliesOnHost = async ({
  ids,
  host
}: {
  ids: string[]
  host: string
}) => {
  return await http.put(`/whitelist/hosts/${host}`, { sandfly_whitelist: ids })
}

export const deleteWhitelistSandfliesOnHost = async ({
  ids,
  host
}: {
  ids: string[]
  host: string
}) =>
  await http.delete(`/whitelist/hosts/${host}`, {
    data: { sandfly_whitelist: ids }
  })
