import { getRefreshToken } from '../auth'
import axios from 'axios'
import { ApiResponse, JWT } from './types'
import { User } from './users'

export type LoginResponseData = {
  access_token: string | null
  refresh_token: string | null
  access_token_data: JWT | null
  refresh_token_data: JWT | null
  access_token_expire_date?: string
  access_token_issue_date?: string
  refresh_token_expire_date?: string
  refresh_token_issue_date?: string
  user: User
}

export type LoginResponseError = {
  code: number
  data: string
  message: string
  status: string
}

export type LoginResponse = ApiResponse<LoginResponseData>

export interface ILogin {
  username: string
  password: string
  full_details: boolean
}

const login = async (props: ILogin): Promise<LoginResponse> => {
  return await axios.post(`/auth/login`, props, { timeout: 4000 })
}

const loginRefresh = async (): Promise<LoginResponse> => {
  return await axios.post(
    `/auth/login_refresh`,
    {
      full_details: true
    },
    {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getRefreshToken()}`
      },
      timeout: 10000,
      timeoutErrorMessage: 'API request has timed out.'
    }
  )
}

export { login, loginRefresh }
