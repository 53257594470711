import { Alert, AlertTitle } from '@mui/material'
import { ApiResponse } from '@utils/api'
import { refreshData, shouldRefresh, isLoggedIn } from '@utils/auth'
import LoadingContext from '@utils/contexts/LoadingContext'
import {
  OptionsObject,
  SnackbarKey,
  SnackbarMessage,
  useSnackbar
} from 'notistack'
import React, { useEffect, useState, useContext } from 'react'

// Todo: Add method to allow for promise all.

export type TUseApiHook<T extends ApiResponse<any>> = {
  loading: boolean
  response: null | T['data']
  error: any
  refresh: (params?: any, options?: { showSuccessMessage?: boolean }) => void
  makeRequest: (params?: any) => Promise<T>
  enqueueSnackbar: (
    message: SnackbarMessage,
    options?: OptionsObject | undefined
  ) => SnackbarKey
  handleRequest: (param?: any) => Promise<T>
  setMute: React.Dispatch<React.SetStateAction<boolean>>
}

type TUseApiProps<Type> = {
  apiMethod: (params?: any) => Promise<Type>
  params?:
    | {
        [key: string]: any
      }
    | string
  requestOnMount?: boolean
  globalLoading?: boolean
  muteErrors?: boolean
}

/**
 * Tracks state for API promise calls.
 */
function useApi<Type extends ApiResponse>(
  props: TUseApiProps<Type>
): TUseApiHook<Type> {
  const { setLoading: setGlobalLoading } = useContext(LoadingContext)
  const {
    apiMethod,
    params,
    requestOnMount = true,
    globalLoading = true,
    muteErrors = false
  } = props
  const { enqueueSnackbar } = useSnackbar()
  const [loading, setLoading] = useState<boolean>(!!props.requestOnMount)
  const [response, setResponse] = useState<null | Type['data']>(null)
  const loggedIn = isLoggedIn()
  const [mute, setMute] = useState<boolean>(muteErrors)
  const [error, setError] = useState<any>(null)

  useEffect(() => {
    requestOnMount && makeRequest(params)
  }, [loggedIn])

  useEffect(() => {
    globalLoading && setGlobalLoading(loading)
  }, [loading])

  const makeRequest = (params: any) => {
    setLoading(true)
    const refresh = shouldRefresh()

    if (refresh) {
      console.info('Refreshing token...')
      ;async () =>
        await refreshData().catch((error) => {
          console.log('error', error)

          !mute &&
            enqueueSnackbar('An error has occurred refreshing your session', {
              variant: 'error'
            })
        })
    }

    return handleRequest(params)
  }

  const handleRequest = async (params: any): Promise<Type> => {
    setError(null)

    const response = await apiMethod(params)
      .then((rawResponse) => {
        if (wasSuccess(rawResponse)) {
          setResponse(rawResponse?.data || {})
          setError(null)
        } else if (was404(rawResponse)) {
          setResponse(null)
        } else if (was402(rawResponse)) {
          !mute &&
            enqueueSnackbar(rawResponse.title, {
              variant: 'info',
              content: rawResponse.detail
            })
        } else {
          setError(rawResponse.data)
          !mute &&
            enqueueSnackbar(rawResponse.title, {
              variant: 'error',
              content: rawResponse.detail
            })
        }

        setLoading(false)

        return rawResponse
      })
      .catch((error) => {
        if (error.response) {
          const { data } = error.response
          setError(data)
          !mute &&
            enqueueSnackbar(data.detail, {
              content: () => {
                return (
                  <Alert
                    variant="filled"
                    severity="error"
                    style={{
                      maxWidth: '30rem',
                      minWidth: '12rem',
                      wordBreak: 'break-word'
                    }}
                  >
                    <AlertTitle>{`${data.title} ${data.status}`}</AlertTitle>
                    {data.detail}
                  </Alert>
                )
              }
            })
        } else {
          setError(error)
          !mute &&
            enqueueSnackbar('API request has failed', {
              variant: 'error'
            })
        }

        setLoading(false)

        return error?.response
      })

    return response
  }

  const refresh: TUseApiHook<ApiResponse<string>>['refresh'] = (
    params,
    options = {}
  ) => {
    const { showSuccessMessage = true } = options
    return makeRequest(params).then((res) => {
      if (wasSuccess(res) && showSuccessMessage) {
        enqueueSnackbar('Data successfully updated', { variant: 'success' })
      }
    })
  }

  return {
    loading,
    response,
    error,
    makeRequest,
    handleRequest,
    enqueueSnackbar,
    setMute,
    refresh
  }
}

export default useApi

export const wasResponseSuccess = (response: ApiResponse<any>) => {
  return response &&
    response.status &&
    response.status >= 200 &&
    response.status < 300
    ? true
    : false
}

export const wasSuccess = (
  response:
    | ApiResponse<any>
    | ApiResponse<any>[]
    | ApiResponse<any>
    | ApiResponse<any>[]
) => {
  if (Array.isArray(response)) {
    return response.every((item) => wasResponseSuccess(item))
  } else {
    return wasResponseSuccess(response)
  }
}

export const was404 = (response: ApiResponse<any> | ApiResponse<any>[]) => {
  if (Array.isArray(response)) {
    return response.every((item) => !!item && item.status === 404)
  } else {
    return !!response && response.status === 404
  }
}

export const was402 = (response: ApiResponse<any> | ApiResponse<any>[]) => {
  if (Array.isArray(response)) {
    return response.every((item) => !!item && item.status === 402)
  } else {
    return !!response && response.status === 402
  }
}
