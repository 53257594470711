import { Status } from '@utils/api'
import React from 'react'

type State = {
  items: Status['nodes']['tasks']['queues']
  loading: boolean
  count: number
}

const defaultState: State = {
  items: [],
  loading: true,
  count: 0
}

const QueueContext = React.createContext(defaultState)

export default QueueContext
