import http from '../http'
import { ApiResponse } from './types'

export type Configuration = {
  config_revision: number
  elastic_replication_url: string
}

export type ConfigurationResponse = ApiResponse<Configuration>

export const getConfiguration = async (): Promise<ConfigurationResponse> =>
  await http.get(`/config`)

export const updateConfiguration = async (
  props: Configuration
): Promise<ConfigurationResponse> =>
  await http.put(`/config`, {
    ...props
  })
