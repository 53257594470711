import SidebarContext from '@utils/contexts/SidebarContext'
import React from 'react'

class SidebarProvider extends React.Component {
  state = {
    isSidebarOpen: true
  }

  setSidebar = (open: boolean = false) => {
    localStorage.setItem('Sandfly.isSidebarOpen', open ? '1' : '0')
    this.setState({ isSidebarOpen: open })
  }

  componentDidMount() {
    const ls = localStorage.getItem('Sandfly.isSidebarOpen')

    if (ls) {
      this.setState({ isSidebarOpen: ls === '1' })
    }
  }

  render() {
    const { children } = this.props
    const { isSidebarOpen } = this.state

    return (
      <SidebarContext.Provider
        value={{
          isSidebarOpen,
          setSidebar: this.setSidebar
        }}
      >
        {children}
      </SidebarContext.Provider>
    )
  }
}

export default SidebarProvider
