import http from '../http'
import { ApiResponse } from './types'

export type TTag = {
  tag: string
  total: number
}

export type TagsResponse = ApiResponse<TTag[]>

export const getHostTags = async (): Promise<TagsResponse> =>
  await http.get(`/tags/hosts`)
