import http from '../http'
import { removeEmpty } from '../misc'
import { ApiResponse } from './types'

export type Credential = {
  credentials_data: string
  credentials_id: string
  credentials_type: 'ssh_key' | 'username'
  id: 'string'
}

export type Credentials = { data: Credential[] }
export type CredentialsResponse = ApiResponse<Credentials>
export type CredentialResponse = ApiResponse<Credential>

export const getCredentials = async (): Promise<CredentialsResponse> =>
  await http.get(`/credentials`)

export const getCredential = async (id: string): Promise<CredentialResponse> =>
  await http.get(`/credentials/${id}`)

export type TCreateCredentialProps = {
  // credentials_id: string
  credentials_name: string
  username: string
  password?: string
  credentials_type: Credential['credentials_type']
  ssh_key_b64: string
  ssh_key_certificate_b64?: string
  ssh_key_password?: string
}

export const createCredential = async (
  props: TCreateCredentialProps
): Promise<CredentialResponse> => {
  const isBrowser = window !== undefined
  const { ssh_key_b64 = '', ssh_key_certificate_b64 = '' } = props
  const newProps = removeEmpty({
    ...props,
    ssh_key_b64: isBrowser && btoa(ssh_key_b64),
    ssh_key_certificate_b64: isBrowser && btoa(ssh_key_certificate_b64)
  })

  return await http.post(`/credentials/${props.credentials_name}`, newProps)
}

export type IUpdateCredentialProps = {
  credentials_name: TCreateCredentialProps['credentials_name']
  username?: TCreateCredentialProps['username']
  password?: TCreateCredentialProps['password']
  credentials_type: TCreateCredentialProps['credentials_type']
  ssh_key_b64?: TCreateCredentialProps['ssh_key_b64']
  ssh_key_certificate_b64?: TCreateCredentialProps['ssh_key_certificate_b64']
  ssh_key_password?: TCreateCredentialProps['ssh_key_password']
}

export const updateCredential = async (
  props: IUpdateCredentialProps
): Promise<ApiResponse<string>> => {
  const isBrowser = window !== undefined
  const { ssh_key_b64 = '', ssh_key_certificate_b64 = '' } = props
  const newProps = removeEmpty({
    ...props,
    ssh_key_b64: isBrowser && btoa(ssh_key_b64),
    ssh_key_certificate_b64: isBrowser && btoa(ssh_key_certificate_b64)
  })

  return await http.put(`/credentials/${props.credentials_name}`, newProps)
}

export const deleteCredential = async (
  id: string
): Promise<ApiResponse<string>> => await http.delete(`/credentials/${id}`)

export const deleteCredentials = async (
  ids: string[]
): Promise<ApiResponse<string>> =>
  await http.delete(`/credentials`, {
    data: {
      credential_ids: ids
    }
  })
