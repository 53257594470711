import React from 'react'
import AuthProvider from './src/utils/providers/AuthProvider'
import LoadingProvider from './src/utils/providers/LoadingProvider'
import QueueProvider from './src/utils/providers/QueueProvider'
import SidebarProvider from './src/utils/providers/SidebarProvider'
import LicenseProvider from './src/utils/providers/LicenseProvider'
import { SnackbarProvider } from 'notistack'

export const wrapRootElement = ({ element }) => (
  <LoadingProvider>
    <SidebarProvider>
      <SnackbarProvider maxSnack={3}>
        <LicenseProvider>
          <AuthProvider>
            <QueueProvider poleF={10000}>{element}</QueueProvider>
          </AuthProvider>
        </LicenseProvider>
      </SnackbarProvider>
    </SidebarProvider>
  </LoadingProvider>
)
