import { ThemeProvider } from '@mui/material'
import ColorModeContext, {
  ColorModeState
} from '@utils/contexts/ColorModeContext'
import { dark, light } from '@utils/theme'
import React from 'react'

type State = {
  colorMode: ColorModeState['colorMode']
}

class ColorModeProvider extends React.Component {
  state: State = {
    colorMode: 'dark'
  }

  toggleColorMode = () => {
    const { colorMode: prevMode } = this.state
    const newMode = prevMode === 'light' ? 'dark' : 'light'
    localStorage.setItem('Sandfly.colorMode', newMode)
    this.setState({ colorMode: newMode })
  }

  componentDidMount() {
    // Todo: Check for browser hint for preferred mode
    const ls = localStorage.getItem('Sandfly.colorMode')

    if (ls) {
      this.setState({ colorMode: ls })
    }
  }

  render() {
    const { children } = this.props
    const { colorMode } = this.state

    return (
      <ColorModeContext.Provider
        value={{
          colorMode,
          toggleColorMode: this.toggleColorMode
        }}
      >
        <ThemeProvider theme={colorMode === 'dark' ? dark : light}>
          {children}
        </ThemeProvider>
      </ColorModeContext.Provider>
    )
  }
}

export default ColorModeProvider
