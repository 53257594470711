import React from 'react'
import CssBaseline from '@mui/material/CssBaseline'
import { Helmet } from 'react-helmet'
import { LicenseInfo } from '@mui/x-data-grid-pro'
import ColorModeProvider from '@utils/providers/ColorModeProvider'

LicenseInfo.setLicenseKey(
  'd8bb4541d003592dbd15fabd89d1d565T1JERVI6MjU4ODcsRVhQSVJZPTE2NTUwMDA5NjYwMDAsS0VZVkVSU0lPTj0x'
)

const TopLayout: React.FC<{}> = ({ children }) => {
  return (
    <React.Fragment>
      <Helmet>
        <meta name="viewport" content="initial-scale=1, width=device-width" />
      </Helmet>
      <ColorModeProvider>
        {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
        <CssBaseline />
        {children}
      </ColorModeProvider>
    </React.Fragment>
  )
}

export default TopLayout
