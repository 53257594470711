import { License, LoginResponseData, User } from '@utils/api'
import React from 'react'
import { AuthData, stub } from '@utils/auth'

export type ContextState = {
  user: User | null
  doLogIn: (props: { username: string; password: string }) => void
  doLogOut: () => void
  loading: boolean
  isLoggedIn: boolean
  authData: AuthData
  updateAuthData: (data?: AuthData) => void
}

export type AuthState = {
  data: AuthData
  isLoggedIn: boolean
  loading: boolean
}

export interface AuthAction {
  type: 'updateAuthData' | 'logout' | 'loaded' | 'refresh'
  data?: LoginResponseData | { license: License } | AuthData
  doRefresh?: () => void
}

const defaultState: ContextState = {
  user: null,
  doLogIn: () => {},
  doLogOut: () => {},
  loading: true,
  isLoggedIn: false,
  authData: stub,
  updateAuthData: () => {}
}

const AuthContext = React.createContext(defaultState)

export default AuthContext
