import http from '../http'
import { ApiResponse } from './types'

export type StatusTimeSeries = {
  data: {
    time: string
    total: number
  }[]
  total: number
}

export type StatusTagSeries = {
  data: {
    tag: string
    total: number
  }[]
}

export type Status = {
  hosts: {
    total: number
    total_active: number
    total_inactive: number
  }
  nodes: {
    queues: {
      name: string
      total: number
    }[]
    tasks: {
      queues: {
        name: string
        total: number
      }[]
      total_tasks: number
    }
  }
  results: {
    hourly: {
      all: StatusTimeSeries
      error: StatusTimeSeries
      alert: StatusTimeSeries
      pass: StatusTimeSeries
    }
  }
  tags: {
    mitre: {
      all: StatusTagSeries
      error: StatusTagSeries
      alert: StatusTagSeries
      pass: StatusTagSeries
    }
    sandfly: {
      all: StatusTagSeries
      error: StatusTagSeries
      alert: StatusTagSeries
      pass: StatusTagSeries
    }
  }
}

export type StatusTags = {
  tags: {
    [key: string]: StatusTimeSeries
  }
}

export type StatusResponse = ApiResponse<Status>
export type StatusTagsResponse = ApiResponse<StatusTags>
export type StatusNodesResponse = ApiResponse<{ nodes: Status['nodes'] }>

export const getStatus = async (): Promise<StatusResponse> =>
  await http.get(`/status`, {
    timeout: 60000
  })

export const getStatusNodes = async (): Promise<StatusNodesResponse> =>
  await http.get(`/status/nodes`)

export const getStatusTags = async (data?: {
  result_status: 'alert' | 'error' | 'pass'
}): Promise<StatusTagsResponse> => await http.post(`/status/tags`, data)
