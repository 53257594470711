import http from '../http'
import { removeEmpty } from '../misc'
import { ApiResponse, ScrollingApiResponse } from './types'

// Alerts Syslog
export type SyslogEntry = {
  hostname: string
  id: string
  name: string
  password: string
  send_all_alerts: boolean
  udp_port: number
  username: string
}
export type SyslogAlerts = SyslogEntry[]
export type SyslogResponse = ScrollingApiResponse<SyslogAlerts>
export type TCreateAlertsSyslog = {
  name: string
  hostname: string
  udp_port?: number | ''
  send_all_alerts?: boolean
}

export const createAlertsSyslog = async (
  props: TCreateAlertsSyslog
): Promise<ApiResponse<string>> => {
  const cleanProps = removeEmpty(props)
  return await http.post(`/alerts/syslog`, cleanProps)
}

export const getAlertsSyslog = async (): Promise<SyslogResponse> =>
  await http.get(`/alerts/syslog`)

export const deleteAlertsSyslog = async (
  id: string
): Promise<ApiResponse<string>> => await http.delete(`/alerts/syslog/${id}`)

// Alerts Email
export type EmailEntry = {
  email_recipients: string[]
  email_sender: string
  id: string
  mail_password: string
  udp_port?: number | ''
  mail_server: string
  mail_use_ssl: boolean
  mail_use_tls: boolean
  mail_username: string
  name: string
}
export type EmailAlerts = EmailEntry[]
export type EmailResponse = ScrollingApiResponse<EmailAlerts>
export type TCreateAlertsEmail = {
  name: string
  email_sender: string
  email_recipients: string[]
  mail_server: string
  mail_username: string
  mail_password: string
  transport_method?: 'tls' | 'unencrypted'
  mail_use_tls?: boolean
  mail_use_ssl?: boolean
  mail_port?: number
}

function isTls({ transport_method }: TCreateAlertsEmail) {
  return transport_method === 'tls'
}

export function booleanToTransport({ mail_use_tls }: EmailEntry) {
  if (mail_use_tls) return 'tls'
  return 'unencrypted'
}

export const createAlertsEmail = async (
  props: TCreateAlertsEmail
): Promise<ApiResponse<string>> => {
  const cleanProps = removeEmpty({
    ...props,
    mail_use_tls: isTls(props)
  })

  return await http.post(`/alerts/email`, cleanProps)
}

export const testAlertsEmail = async (
  name: EmailEntry['name']
): Promise<ApiResponse<string>> => await http.post(`/alerts/email/test/${name}`)

export const getAlertsEmail = async (): Promise<EmailResponse> =>
  await http.get(`/alerts/email`)

export const deleteAlertsEmail = async (
  id: string
): Promise<ApiResponse<string>> => await http.delete(`/alerts/email/${id}`)
