import http from '../http'
import { ApiResponse } from './types'

export type Users = {
  users: User[]
}

export type User = {
  username: string
  created_date: null | string
  email: string
  full_name: string
  last_login_date: null | string
  last_login_ip: null | string
  logins: string[]
  logins_failed: string[]
  logins_failed_count: number
  password: string
  preferences: string
  roles: UserRoles[]
}

export type TUserConfig = {
  full_name?: string
  username: string
  email?: string
  roles?: UserRoles[]
  password?: string
  reenter?: string
}

export type UserRoles = 'admin' | 'user' | 'operator' | 'system'

export type UsersResponse = ApiResponse<Users>

export type UserResponse = ApiResponse<User>

export const getUsers = async (): Promise<UsersResponse> => {
  return await http.get(`/users`)
}

export const getUser = async (userName: string): Promise<UserResponse> => {
  return await http.get(`/users/${userName}`)
}

export const createUser = async (
  config: TUserConfig
): Promise<ApiResponse<string>> => {
  return await http.post(`/users/${config.username}`, config)
}

export const updateUser = async (
  config: TUserConfig
): Promise<ApiResponse<string>> => {
  const { username, ...data } = config
  return await http.put(`/users/${username}`, data)
}

export const deleteUser = async (
  userName: string
): Promise<ApiResponse<string>> => {
  return await http.delete(`/users/${userName}`)
}
