import http from '../http'
import { ApiResponse } from './types'

export type License = {
  customer: {
    email: string
    name: string
    website: string
  }
  date: {
    expiry: string
    issued: string
    warning: string
  }
  license_level: TLicenseLevel
  limits: {
    features: TFeatureBooleans[] | null
    alerts: number
    data_retention_max_hours: number
    email_notifications: number
    hosts: number
    jump_hosts: number
    named_queues: number
    schedules: number
    syslog_notifications: number
    users: number
  }
  usage: {
    alerts: number
    data_retention_max_hours: number
    email_notifications: number
    hosts: number
    jump_hosts: number
    named_queues: number
    schedules: number
    syslog_notifications: number
    users: number
  }
  version: number
}

export type TFeatureBooleans =
  | 'host_tags'
  | 'sandfly_auto_response'
  | 'user_roles'
  | 'custom_sandflies'
  | 'policy_sandflies'
  | 'recon_sandflies'
  | 'incident_response_sandflies'
  | 'sandfly_hunter'
  | 'ssh_stalker'
  | 'process_stalker'
  | 'file_stalker'
  | 'user_stalker'
  | 'directory_stalker'
  | 'video_training'
  | 'standard_support'
  | 'pagerduty_notifications'
  | 'reports'
  | '2fa'
  | 'elasticsearch_replication'
  | 'elasticsearch_external'
  | 'splunk_connector'
  | 'ad_hoc_scan'
  | 'enterprise_support'
  | 'key_vault'
  | 'sso'
  | 'demo'

export type TFeatureLimits = keyof License['usage']

export type TFeatureKey = TFeatureLimits | TFeatureBooleans

export type TLicenseLevel = -1 | 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9

export type LicenseResponse = ApiResponse<License>

export const getLicense = async (): Promise<LicenseResponse> =>
  await http.get(`/license`)

export const addLicense = async (data: {
  license_key: string
}): Promise<LicenseResponse> => await http.post(`/license`, data)

export const updateLicense = async (data: {
  license_key: string
}): Promise<LicenseResponse> => await http.put(`/license`, data)

export const deleteLicense = async (): Promise<ApiResponse<string>> =>
  await http.delete(`/license`)
