import LoadingContext from '@utils/contexts/LoadingContext'
import React from 'react'

class LoadingProvider extends React.Component {
  state = {
    isLoading: false,
    loading: []
  }

  setLoading = (loading: boolean = false) => {
    const queue: boolean[] = [...this.state.loading]

    if (loading) {
      queue.push(loading)
    } else {
      queue.pop()
    }

    this.setState({ loading: queue })
  }

  render() {
    const { children } = this.props
    const { loading } = this.state

    return (
      <LoadingContext.Provider
        value={{
          isLoading: loading.length > 0,
          loading,
          setLoading: this.setLoading
        }}
      >
        {children}
      </LoadingContext.Provider>
    )
  }
}

export default LoadingProvider
