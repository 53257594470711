import AuthContext, {
  AuthAction,
  AuthState,
  ContextState
} from '@utils/contexts/AuthContext'
import { wasSuccess } from '@utils/hooks/useApi'
import { Alert, AlertTitle } from '@mui/material'
import { login } from '@utils/api'
import {
  AuthData,
  deleteAuthLSData,
  getAuthLSData,
  getLoginStatus,
  refreshData,
  setAuthLSData,
  stub
} from '@utils/auth'
import { navigate } from 'gatsby'
import React, { FC, useContext, useEffect, useReducer } from 'react'
import { useSnackbar } from 'notistack'
import LicenseContext from '@utils/contexts/LicenseContext'

const REFRESH_F = 1000 * 60 * 4
const isBrowser = typeof window !== `undefined`

const reducer: React.Reducer<AuthState, AuthAction> = (
  prevState,
  action
): AuthState => {
  switch (action.type) {
    case 'updateAuthData':
      const { data } = action
      const newData = {
        ...prevState.data,
        ...(data && data)
      }
      setAuthLSData(newData)
      return {
        data: newData as AuthData,
        isLoggedIn: getLoginStatus(newData),
        loading: false
      }
    case 'logout':
      deleteAuthLSData()

      return { data: stub, isLoggedIn: false, loading: false } as AuthState

    case 'loaded':
      return { ...prevState, loading: false } as AuthState

    case 'refresh':
      const { doRefresh } = action
      const isLoggedIn = getLoginStatus(prevState.data)
      console.info('Token Refreshing... ', isLoggedIn && !!doRefresh)

      isLoggedIn && doRefresh && doRefresh()

      return { ...prevState, isLoggedIn } as AuthState

    default:
      throw new Error()
  }
}

const AuthProvider: FC = ({ children }) => {
  const { refreshData: refreshLicenseData } = useContext(LicenseContext)
  const initialData = getAuthLSData()
  const initialStatus = getLoginStatus(initialData)
  const [state, dispatch] = useReducer(reducer, {
    data: initialData,
    isLoggedIn: initialStatus,
    loading: true
  })

  const { enqueueSnackbar } = useSnackbar()

  const { isLoggedIn, data, loading } = state

  const updateAuthData = (newData: AuthAction['data']) => {
    dispatch({ type: 'updateAuthData', data: newData })
  }

  const doLogIn: ContextState['doLogIn'] = ({ username, password }) => {
    if (!username || !password) {
      return false // Todo: pass error to UI
    }

    if (state.isLoggedIn) {
      return navigate('/dashboard/')
    }

    const request = login({ username, password, full_details: true })

    request
      .then((response) => {
        if (wasSuccess(response) && response.data) {
          updateAuthData(response.data)
          // refreshExtendedData()
          navigate('/dashboard/')
        } else {
          enqueueSnackbar(response.detail, { variant: 'error' })
          dispatch({ type: 'logout' })
        }
      })

      .catch((error) => {
        if (error.response) {
          const { data } = error.response
          enqueueSnackbar(data.detail, {
            content: () => {
              return (
                <Alert
                  variant="filled"
                  severity="error"
                  style={{
                    maxWidth: '30rem',
                    minWidth: '12rem',
                    wordBreak: 'break-word'
                  }}
                >
                  <AlertTitle>{`${data.title} ${data.status}`}</AlertTitle>
                  {data.detail}
                </Alert>
              )
            }
          })
        } else {
          enqueueSnackbar('Login attempt has failed due to server error.', {
            variant: 'error'
          })
        }
        dispatch({ type: 'logout' })
      })
  }

  const doLogOut = () => dispatch({ type: 'logout' })

  const doRefresh = async () => {
    console.log('doRefresh')

    const response = await refreshData()

    if (response && response.data) {
      updateAuthData(response.data)
    }
  }

  // Check and refresh token, to keep user logged in.
  useEffect(() => {
    console.log('Auth Effect')

    if ((!isLoggedIn && isBrowser) || window.location.pathname === '/logout') {
      dispatch({ type: 'loaded' })
      console.info('Auth: logged out')
    } else {
      console.info('Auth: logged in, should refresh')
      dispatch({ type: 'refresh', doRefresh })
      refreshLicenseData()
      const timer = setInterval(() => {
        console.info('Auth: Refresh timer fired')
        dispatch({ type: 'refresh', doRefresh })
      }, REFRESH_F)

      return () => {
        clearInterval(timer)
      }
    }

    
  }, [isLoggedIn, isBrowser])

  return (
    <AuthContext.Provider
      value={{
        user: data?.user || null,
        isLoggedIn,
        doLogIn,
        doLogOut,
        //loading: isLoading,
        loading,
        updateAuthData,
        // refreshExtendedData,
        authData: data
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

export default AuthProvider
