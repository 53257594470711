import http from '../http'
import { ApiResponse } from './types'

type ScanConfig = { [key: string]: any } & {
  host_ids: string[]
  sandfly_list: string[]
}

export const scanHosts = async (
  config: ScanConfig
): Promise<ApiResponse<string>> => await http.post(`/scan`, config)
