import http from '../http'
import { ApiResponse } from './types'

export type TaskQueueName = {
  queues: {
    name: string
    total: number
  }[]
}

export type TaskQueueNameResponse = ApiResponse<TaskQueueName>

export const getQueueNames = async (): Promise<TaskQueueNameResponse> =>
  await http.get(`/tasks/queues/names`)

// TODO: Type this
export const getQueueWaiting = async (): Promise<any> =>
  await http.get(`/tasks/queues/waiting`)

export const deleteTasks = async (): Promise<any> =>
  await http.delete(`/tasks/all`)
