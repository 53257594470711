import axios from 'axios'
import { getToken, isLoggedIn, logout } from './auth'
// TODO: implement request caching
// import { setup } from 'axios-cache-adapter'

axios.defaults.baseURL = process.env.GATSBY_SANDFLY_API
axios.defaults.headers.post['Accept'] = 'application/json'
axios.defaults.headers.post['Content-Type'] = 'application/json'
axios.defaults.headers.get['Content-Type'] = 'application/json'

const isBrowser = typeof window !== `undefined`

// TODO: implement request caching
// const http = setup({
//   cache: {
//     maxAge: 60 * 1000,
//     // Invalidate only when a specific option is passed through config
//     invalidate: async (config, request) => {
//       if (request.clearCacheEntry) {
//         await config.store.removeItem(config.uuid)
//       }
//     }
//   }
// })

const http = axios.create({
  timeout: 45000,
  timeoutErrorMessage: 'API request has timed out.'
})

http.interceptors.request.use((config) => {
  if (isLoggedIn()) {
    config.headers['Authorization'] = `Bearer ${getToken()}`
  } else {
    delete config.headers['Authorization']
  }

  // Todo: If not Auth request and no auth header - do logout.
  return config
})

http.interceptors.response.use(
  (response) => {
    return response
  },
  (error) => {
    if (
      typeof error.response !== 'undefined' &&
      error.response.status === 401
    ) {
      logout(() => {
        if (isBrowser && window.location.pathname !== '/') {
          window.location.href = '/'
        }
      })
    } else {
      return Promise.reject(error)
    }
  }
)

export default http
