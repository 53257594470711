import { getStatusNodes, StatusNodesResponse } from '@utils/api'
import AuthContext from '@utils/contexts/AuthContext'
import QueueContext from '@utils/contexts/QueueContext'
import useApi from '@utils/hooks/useApi'
import React, { FC, useContext, useEffect } from 'react'

const STUB = {
  total_tasks: 0,
  queues: []
}

const QueueProvider: FC<{ poleF: number }> = ({ children, poleF = 10000 }) => {
  const { isLoggedIn } = useContext(AuthContext)

  const { response, loading, makeRequest } = useApi<StatusNodesResponse>({
    apiMethod: getStatusNodes,
    globalLoading: false,
    requestOnMount: isLoggedIn,
    muteErrors: true
  })
  const task = response?.nodes.tasks || STUB

  useEffect(() => {
    // Check API at x interval
    if (!isLoggedIn) return

    const timer = setInterval(() => {
      if (!isLoggedIn) {
        clearInterval(timer)
      } else {
        makeRequest()
      }
    }, poleF)
    return () => clearInterval(timer)
  }, [isLoggedIn])

  return (
    <QueueContext.Provider
      value={{ items: task.queues, loading, count: task.total_tasks }}
    >
      {children}
    </QueueContext.Provider>
  )
}

export default QueueProvider
