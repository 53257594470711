import { createTheme, darken, lighten, PaletteMode } from '@mui/material'
import { Shadows } from '@mui/material/styles/shadows'
import { TypographyOptions } from '@mui/material/styles/createTypography'

const styles = {
  primary: '#2a8e5e',
  secondary: '#00a4c3',
  black: '#151714',
  white: '#ffffff',
  font: "'Monda', 'Franklin Gothic Medium', Tahoma, sans-serif",
  fontMono: "'Roboto Mono', monospace",
  bg: '#fafafa',
  darkBg: '#1d211b',
  paper: '#ffffff',
  darkPaper: '#292d28'
}

const typography: TypographyOptions = {
  fontFamily: 'Monda, sans-serif',
  h1: {
    fontSize: '2.5rem',
    fontWeight: 400,
    marginBottom: '0.75rem',

    '& + h2, & + h3, & + h4, & + h5, & + h6': {
      marginTop: '0',
      marginBottom: '0.75rem'
    }
  },
  h2: {
    fontSize: '2rem',
    fontWeight: 700,
    marginBottom: '0.25em'
  },
  h3: {
    fontSize: '1.75rem',
    fontWeight: 700,
    marginBottom: '0.5em'
  },
  h4: {
    fontSize: '1.5rem',
    fontWeight: 700,
    marginBottom: '0.5em'
  },
  h5: {
    fontSize: '1.25rem',
    fontWeight: 700,
    marginBottom: '0.5em'
  },
  h6: {
    fontSize: '1.125rem',
    fontWeight: 700,
    marginBottom: '0.5em'
  },
  subtitle1: {
    fontWeight: 700
  },
  subtitle2: {},
  body1: {},
  body2: {},
  button: {},
  caption: {},
  overline: {}
}

const s1 = `0 0.2px 2.2px rgba(0, 0, 0, 0.008),
  0 0.5px 5.3px rgba(0, 0, 0, 0.012),
  0 1px 10px rgba(0, 0, 0, 0.015),
  0 1.8px 17.9px rgba(0, 0, 0, 0.018),
  0 3.3px 33.4px rgba(0, 0, 0, 0.022),
  0 8px 80px rgba(0, 0, 0, 0.03)`

const s2 = `0 2.8px 2.2px rgba(0, 0, 0, 0.011),
  0 6.7px 5.3px rgba(0, 0, 0, 0.016),
  0 12.5px 10px rgba(0, 0, 0, 0.02),
  0 22.3px 17.9px rgba(0, 0, 0, 0.024),
  0 41.8px 33.4px rgba(0, 0, 0, 0.029),
  0 100px 80px rgba(0, 0, 0, 0.04)`

// https://shadows.brumm.af/
const shadows: Shadows = [
  'none',
  s1,
  s2,
  s2,
  s2,
  s2,
  s2,
  s2,
  s2,
  s2,
  s2,
  s2,
  s2,
  s2,
  s2,
  s2,
  s2,
  s2,
  s2,
  s2,
  s2,
  s2,
  s2,
  s2,
  s2
]

const base = {
  typography,
  shadows
}

const getDesignTokens = (mode: PaletteMode) => ({
  ...base,
  palette: {
    mode,
    ...(mode === 'light'
      ? {
          background: {
            default: styles.bg,
            paper: styles.paper
          },
          common: {
            black: styles.black,
            white: styles.white
          },
          primary: {
            main: styles.primary
          },
          secondary: {
            main: styles.secondary
          }
        }
      : {
          background: {
            default: styles.darkBg,
            paper: styles.darkPaper
          },
          common: {
            black: styles.black,
            white: styles.white
          },
          primary: {
            main: styles.primary
          },
          secondary: {
            main: styles.secondary
          }
        })
  }
})

const theme = createTheme({
  // see all variables here: https://material-ui.com/customization/default-theme/
  ...base,
  ...getDesignTokens('light')
})

const dark = createTheme({
  // see all variables here: https://material-ui.com/customization/default-theme/
  ...base,
  ...getDesignTokens('dark')
})

const getSurfaceColor = (color: string, mode: PaletteMode) => {
  return mode === 'dark' ? darken(color, 0.65) : lighten(color, 0.4)
}

const getHoverSurfaceColor = (color: string, mode: PaletteMode) =>
  mode === 'dark' ? darken(color, 0.6) : lighten(color, 0.35)

const getBackgroundColor = (color: string, mode: PaletteMode) => {
  return mode === 'dark' ? darken(color, 0.8) : lighten(color, 0.9)
}

const getHoverBackgroundColor = (color: string, mode: PaletteMode) =>
  mode === 'dark' ? darken(color, 0.6) : lighten(color, 0.8)

export default theme
export {
  getDesignTokens,
  theme as light,
  dark,
  getBackgroundColor,
  getHoverBackgroundColor,
  getSurfaceColor,
  getHoverSurfaceColor
}
