import React from 'react'

type State = {
  isSidebarOpen: boolean
  setSidebar: (open?: boolean) => void
}

const defaultState: State = {
  isSidebarOpen: true,
  setSidebar: () => {}
}

const SidebarContext = React.createContext(defaultState)

export default SidebarContext
