/**
 * Converts strings in snake case to title case
 * @param string string
 * @returns string
 */
export function underscoreToTitleCase(string: string): string {
  return string.replace(/_/g, ' ').replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
  })
}

export function isNumeric(str: any) {
  if (typeof str !== 'string') return false // we only process strings!
  return (
    !isNaN(str as any) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
    !isNaN(parseFloat(str))
  ) // ...and ensure strings of whitespace fail
}

export function getPropertyFromDotNotation(
  string: string,
  object: { [key: string]: any }
) {
  try {
    if (string.indexOf('[]') > -1) {
      throw new Error('Array sub-properties are not supported currently')
    }

    const value = string.split('.').reduce((o, i) => o[i], object)
    return value
  } catch (error) {
    console.warn(`Not able to access ${string}`)
    return ''
  }
}

export function arrayFromCommaString(string: string) {
  return string
    .replace(/\n/g, ',')
    .split(',')
    .map((newString) => newString.trim())
}

export function removeEmpty(obj: object) {
  return Object.entries(obj)
    .filter(([_, v]) => v != null && v !== '')
    .reduce((acc, [k, v]) => ({ ...acc, [k]: v }), {})
}

export function numberFormat(number: number, digits: number = 3) {
  const lookup = [
    { value: 1, symbol: '' },
    { value: 1e3, symbol: 'k' },
    { value: 1e6, symbol: 'M' },
    { value: 1e9, symbol: 'G' },
    { value: 1e12, symbol: 'T' },
    { value: 1e15, symbol: 'P' },
    { value: 1e18, symbol: 'E' }
  ]
  var item = lookup
    .slice()
    .reverse()
    .find(function (item) {
      return number >= item.value
    })

  const result: number = item ? number / item.value : 0

  return `${new Intl.NumberFormat('en-US', {
    maximumSignificantDigits: digits
  }).format(result)}${item ? item.symbol : ''}`
}

export const mergeArrays = (
  arr1: any[] = [],
  arr2: any[] = [],
  property: string = ''
) => {
  return arr1.map((obj) => {
    const match = arr2.find((el) => el[property] === obj[property])
    return match || obj
  })
}
