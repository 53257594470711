import http from '../http'
import { ApiResponse, ScrollingApiResponse } from './types'

export type AuditLogEntry = {
  id: string
  ip_addr: string
  message: string
  record_type: string
  timestamp: string
  username: string
}

export type AuditLog = AuditLogEntry[]
export type AuditLogResponse = ScrollingApiResponse<AuditLog>

export const getAuditLog = async (): Promise<AuditLogResponse> =>
  await http.get(`/audit`)

export const deleteAuditLog = async (): Promise<ApiResponse<string>> =>
  await http.delete(`/audit`)
