import { License, TFeatureKey } from './api'

export const UPGRADE_URL = 'https://www.sandflysecurity.com/pricing/'
export const UPGRADE_PAGE = '/upgrade/'

export const LS_LICENSE_NAMESPACE = 'License.data'

export const licenseStub: License = {
  customer: {
    email: '',
    name: 'Unlicensed',
    website: ''
  },
  date: {
    expiry: '',
    issued: '',
    warning: ''
  },
  license_level: -1,
  limits: {
    features: [],
    alerts: 0,
    data_retention_max_hours: 24,
    email_notifications: 0,
    hosts: 0,
    jump_hosts: 0,
    named_queues: 0,
    schedules: 0,
    syslog_notifications: 0,
    users: 0
  },
  usage: {
    alerts: 0,
    data_retention_max_hours: 24,
    email_notifications: 0,
    hosts: 0,
    jump_hosts: 0,
    named_queues: 0,
    schedules: 0,
    syslog_notifications: 0,
    users: 0
  },
  version: 0
}

type TGetUpgradeUrl = {
  feature?: TFeatureKey
  componentName?: string
}


export const getUpgradeUrl = (props: TGetUpgradeUrl = {}): string => {
  const { feature, componentName } = props
  const params = new URLSearchParams(
    '?utm_source=sandfly+app&utm_medium=app+upgrade+prompt&utm_campaign=license+upgrade'
  )

  if (feature) params.set('utm_content', `app ${feature}` as string)
  if (componentName) params.set('utm_medium', componentName)

  return `${UPGRADE_URL}?${params.toString()}`
}
